import React from 'react'
import { Outlet } from 'react-router-dom'
function LayoutAutenticacion() {
  return (
    <div className='min-h-screen flex items-center justify-center bg-with text-black'>
        <Outlet/>
    </div>
  )
}
export default LayoutAutenticacion

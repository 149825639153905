import * as React from "react";
import logotipo from "./logo2.png";
import splash from "./imagen.jpg";
import { signInWithGoogle } from "../../data/fire";
import { auth } from "../../data/fire"; 
 import {RiEyeFill,RiLockFill,
    RiMailFill
  } from "react-icons/ri";
  import { Link } from "react-router-dom";
const Login = ({ setAuthState, setUsuario }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");


  return (
    <div className="flex w-full h-screen">
      <div className="w-full flex items-center justify-center lg:w-1/2 bg-gray-200 ">
        <div className="bg-secondary-100 px-10 py-5 rounded-2xl border-2 border-gray-800">
          <h1 className="text-4xl w-full text-center bg-secondary-100 text-white mt-2 font-simbold">
            Bienvenidos
          </h1>

          <p className="flex items-center justify-center">
            <img
              className="h-autobg-secondary-100 max-w-xs"
              src={logotipo}
              alt="logotipo"
            />
          </p>
          <form className="bg-secondary-100">
            <div className="realtive mb-4">
              <RiMailFill className="absolute ml-2 my-3 text-white"/>
               <input
                type="email"
                className="py-2 px-8 pr-4 text-white bg-secondary-900 w-full outline-none rounded-lg"
                placeholder="Correo electronico"
              />
            

            </div>
            <div className="realtive mb-4">
             <RiLockFill className="absolute ml-2 my-3 text-white"/>
               <input
                type="password"
                className="py-2 px-8 pr-10 text-white bg-secondary-900 w-full outline-none rounded-lg"
                placeholder="Contraseña"
              />              
            </div>
            <div className="relative">
            <button className="w-full py-2 px-8 rounded-xl text-black  bg-btnPrimary-100 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">Ingresar</button>
            </div>
            <div className="felx flex-col items-center gap-4">
             <Link className="text-white py-3 px-4 hover:text-btnPrimary-100 transition-colors" to="/auth/recuperar">Olvido su contraseña?</Link>            
            </div>           
          </form>
          <div className="mt-6 flex flex-col gap-y-4 ">
            <button
              onClick={signInWithGoogle}
              className="flex border-2 rounded-xl py-2 bg-white border-gray-200 items-center justify-center gap-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.26644 9.76453C6.19903 6.93863 8.85469 4.90909 12.0002 4.90909C13.6912 4.90909 15.2184 5.50909 16.4184 6.49091L19.9093 3C17.7821 1.14545 15.0548 0 12.0002 0C7.27031 0 3.19799 2.6983 1.24023 6.65002L5.26644 9.76453Z"
                  fill="#EA4335"
                />
                <path
                  d="M16.0406 18.0142C14.9508 18.718 13.5659 19.0926 11.9998 19.0926C8.86633 19.0926 6.21896 17.0785 5.27682 14.2695L1.2373 17.3366C3.19263 21.2953 7.26484 24.0017 11.9998 24.0017C14.9327 24.0017 17.7352 22.959 19.834 21.0012L16.0406 18.0142Z"
                  fill="#34A853"
                />
                <path
                  d="M19.8342 20.9978C22.0292 18.9503 23.4545 15.9019 23.4545 11.9982C23.4545 11.2891 23.3455 10.5255 23.1818 9.81641H12V14.4528H18.4364C18.1188 16.0119 17.2663 17.2194 16.0407 18.0108L19.8342 20.9978Z"
                  fill="#4A90E2"
                />
                <path
                  d="M5.27698 14.2663C5.03833 13.5547 4.90909 12.7922 4.90909 11.9984C4.90909 11.2167 5.03444 10.4652 5.2662 9.76294L1.23999 6.64844C0.436587 8.25884 0 10.0738 0 11.9984C0 13.918 0.444781 15.7286 1.23746 17.3334L5.27698 14.2663Z"
                  fill="#FBBC05"
                />
              </svg>
              Ingresar con Google
            </button>
          </div>
        </div>
      </div>
      <div className="hidden  bg-white relative lg:flex h-full w-1/2 items-center justify-center bg-white-200">
        <img className="h-auto bg-white max-w-xs" src={splash} alt="splash" />
      </div>
    </div>
  );
};
export default Login;

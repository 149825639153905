import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { RiTextDirectionR, RiArrowDropRightLine, RiArrowDropLeftLine } from "react-icons/ri";

function Ejecutivos() {
  const [verdomicilio, setVerdomicilio] = useState(false);
  //
  const [ejecutivos, setEjecutivos] = useState([]);
  useEffect(() => {
    fetch("http://localhost/apicob/ejecutivos")
      .then((result) => result.json())
      .then((data) => {
        setEjecutivos(data);

      }, []);
  }, []);

  return (
    <div>
      <div>Ejecutivos</div>
      <div class="relative overflow-x-auto shadow-md ">
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-white bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Nombre
                </th>
              </tr>
            </thead>
            <tbody>
              {ejecutivos.map((ejecutivo) => {
                return (
                  <tr
                     onClick={() => setVerdomicilio(!verdomicilio)}
                    key={ejecutivo.ejeId}
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td
                      scope="row"
                      class="flex flex-col  justify-around px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div className="w-full flex justify-between">
                        <RiArrowDropRightLine className="w-[5%] text-right text-white text-[3rem] relative -top-4"
                         // className={/*`${!gerente.cteDomicilios.lenghth==0 && "hidden"} relative -top-2 font-bold text-4xl text-primary hover:text-white transition-all`*/}
                        />
                       <span className="w-[85%] text-left text-primary" >{ejecutivo.ejeNombre} </span>                       
                        <span className="w-[10%] text-primary">{ejecutivo.ejeEstado}</span>                                           
                      </div>
                      <div className={`ml-10 px-5 ${!verdomicilio && "hidden"}`}>
                        <table className="w-full text-sm text-primary-100">
                          <thead>
                            <tr className="bg-btnPrimary-100 text-white px-2">                            
                              <th className="text-left px-5">Domiclio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*gerente.cteDomicilios.map((domicilio) => {
                              return (
                                <tr key={domicilio.DomCteId}>
                                  <td className="px-2 w-[35px] text-right">{domicilio.DomId}</td>
                                  <td>{domicilio.DomCalle}</td>
                                </tr>
                              );
                            })*/}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Ejecutivos;

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logotipo from '../auth/Login/logo2.png'
import avatardefault from '../auth/Login/avatardefault.png'
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { RxGear } from "react-icons/rx";
import { ImExit } from "react-icons/im";
import { RiHome4Fill, RiFileList2Line, RiMenuLine, RiMoneyDollarBoxLine } from 'react-icons/ri';
import { GrCatalog } from "react-icons/gr";
const Sidebar2 = ({ perfil, children }) => {
    const [identidad, setIdentidad] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [subMenuCatalogo, setSubMenuCatalogo] = useState(false);
    const [subMenuCobranza, setSubMenuCobranza] = useState(false);
    const [subMenuReportes, setSubMenuReportes] = useState(false);
    const [subMenuConfiguracion, setSubMenuConfiguracion] = useState(false);


    useEffect(() => {
        setIdentidad(perfil[0])
    }, ([]))

    return (
        <>
            <aside className="h-screen">
            <nav className={`h-full flex flex-col bg-secondary border-r shadow-sm shadow-gray-50 ${expanded ? "" : "w-12"}`}>
                    <div className='p-3 pb-2 flex justify-between items-center'>
                        <img className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"}`} src={logotipo} />
                        <button onClick={() => setExpanded((cur) => !cur)} className='p-1.5 rounded-lg bg-primary-900 hover:bg-primary-100'>
                            {expanded ? <LuChevronLeft /> : <RiMenuLine />}
                        </button>
                    </div>
                    <div className='flex flex-col items-center justify-center w-full h-[10vh] pt-1.5 '>
                        <h2 className={`${expanded ? "" : "hidden"} mt-5 text-gray-400  tracking-[2px]`}>{identidad.usuario}</h2>
                    </div>
                    <div className='h-full mx-2 flex flex-col justify-between'>
                        <ul className='w-full text-[12px]'>
                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                to="/"
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <RiHome4Fill className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Inicio</span>
                                </Link>

                            </li>         
                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                    onClick={() => { setSubMenuCobranza(!subMenuCobranza) }}
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <RiMoneyDollarBoxLine className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Prestamos</span>
                                    <LuChevronRight
                                        className={`font-bold text-2xl ${expanded ? "" : "hidden"} ${subMenuCobranza && "rotate-90"} transition-all`} />
                                </Link>
                            </li>                           
                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                    onClick={() => { setSubMenuCobranza(!subMenuCobranza) }}
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <RiMoneyDollarBoxLine className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Cobranza</span>
                                    <LuChevronRight
                                        className={`font-bold text-2xl ${expanded ? "" : "hidden"} ${subMenuCobranza && "rotate-90"} transition-all`} />
                                </Link>
                            </li>                   <li className='text-primary-900 hover:text-white'>
                                <Link
                                    onClick={() => { setSubMenuReportes(!subMenuReportes) }}
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <RiFileList2Line className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Reportes</span>
                                    <LuChevronRight
                                        className={`font-bold text-2xl ${expanded ? "" : "hidden"} ${subMenuReportes && "rotate-90"} transition-all`} />
                                </Link>
                                <div className={`w-full flex ${subMenuReportes ? "" : "hidden"} text-black`}>
                                    <ul className='w-full text-[12px] pb-10'>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link
                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Reporte 1</span>
                                            </Link>
                                        </li>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link
                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Reporte 2</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {/* Grupos */}
                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                    onClick={() => { setSubMenuCatalogo(!subMenuCatalogo) }}
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <GrCatalog className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Catalogos</span>
                                    <LuChevronRight
                                        className={`font-bold text-2xl ${expanded ? "" : "hidden"} ${subMenuCatalogo && "rotate-90"} transition-all`} />
                                </Link>
                                <div className={`w-full flex ${subMenuCatalogo ? "" : "hidden"} text-black `}>
                                    <ul className='w-full text-[12px] pb-1'>
                                    <li className='text-primary-900 hover:text-white'>
                                            <Link
                                             to="cat/grupos"
                                               className={`w-[90%] mb-[3px] hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 transition-colors flex items-center font-bold 
                                                gap-2 py-1 px-1 mb-1 border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Grupos</span>
                                            </Link>
                                        </li>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link
                                             to="cat/clientes"
                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Clientes</span>
                                            </Link>
                                        </li>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link

                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Gerentes</span>
                                            </Link>
                                        </li>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link

                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Ejecutivos</span>
                                            </Link>
                                        </li>
                                        <li className='text-primary-900 hover:text-white'>
                                            <Link

                                               className={`w-[90%] mb-[3px] 
                                                hover:bg-primary-900 hover:rounded-md
                                                hover:bg-primary-900 
                                                transition-colors flex items-center font-bold gap-2 py-1 px-1 mb-1  
                                               border-l border-secondary py-1 px-7 text-[.7.5rem] border-l border-primary-900 ml-2 
                                               block relative before:w-3 before:h-3 before:absolute
                                               ${expanded ? "before:bg-gray-600 gprimary-900 before:rounded-full before:border-secondary-100 "+
                                               " before:-left-[7px] before:top-1/2 before:-translate-y-1/2 before:border-2" : "hidden"}`} >
                                                <RiFileList2Line className='text-[1.5rem]' />
                                                <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Supervisores</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>




                            </li>

                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                    onClick={() => { setSubMenuConfiguracion(!subMenuConfiguracion) }}
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <RxGear className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"}`}>Configuracion</span>
                                    <LuChevronRight
                                        className={`font-bold text-2xl ${expanded ? "" : "hidden"} ${subMenuConfiguracion && "rotate-90"} transition-all`} />
                                </Link>
                            </li>
         
                        </ul>
                        <ul className='w-full text-[12px] pb-10'>
                            <li className='text-primary-900 hover:text-white'>
                                <Link
                                    className='w-full mb-[3px] hover:bg-primary-900
                                transition-colors flex items-center font-bold gap-4 py-1 px-1 mb-1 rounded-md' >
                                    <ImExit className='text-[1.5rem]' />
                                    <span className={`w-full flex ${expanded ? "" : "hidden"} `}>Salir</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                </nav>
            </aside>
        </>
    )
}
export default Sidebar2;

export const MenuCatalogos = ({ expanded, subMenuCatalogos }) => {
    const [subMenu, setSubmenu] = useState(false)
    return (<>
        <div className={`${expanded ? "w-full ml-2 bg-white" : "ml-4 w-[10vw] ronded-md bg-gray-500 shadow-sm"}`}>
            hola</div>
    </>)
}

import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  RiTextDirectionR,
  RiUser2Fill,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
} from "react-icons/ri";
import Modalgerente from "../../../components/Modalgerente";
function Gerentes() {
  const [verdomicilio, setVerdomicilio] = useState(false);
  //
  const [gerentes, setGerenetes] = useState([]);
  useEffect(() => {
    fetch("http://80.211.42.157/apicob/gerentes")
      .then((result) => result.json())
      .then((data) => {
        setGerenetes(data);
        console.log(gerentes);
      }, []);
  }, []);

  return (
    <div>
      <div className="p-2 bg-secondary">
        <div>
          <h1 className="text-2xl text-gray-600">Gerentes</h1>
        </div>
        <div className="flex py-5">
          <button className=" flex items-center gap-2 h-[35px] bg-primary text-base text-white p-2 rounded-[5px]">
            <RiUser2Fill /> Agregar gerente
          </button>
        </div>
      </div>
      <div class="relative overflow-x-auto shadow-md ">
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-white bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Nombre
                </th>
              </tr>
            </thead>
            <tbody>
              {gerentes.map((gerente) => {
                return (
                  <tr
                    onClick={() => setVerdomicilio(!verdomicilio)}
                    key={gerente.gerId}
                    class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <td
                      scope="row"
                      class="flex flex-col  justify-around px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div className="w-full flex justify-between">
                        <RiArrowDropRightLine
                          className="w-[5%] text-right text-white text-[3rem] relative -top-4"
                          // className={/*`${!gerente.cteDomicilios.lenghth==0 && "hidden"} relative -top-2 font-bold text-4xl text-primary hover:text-white transition-all`*/}
                        />
                        <span className="w-[85%] text-left text-primary">
                          {gerente.gerNombre}{" "}
                        </span>

                        <span className="w-[10%] text-primary">
                          {gerente.gerEstado}
                        </span>
                      </div>
                      <div
                        className={`ml-10 px-5 ${!verdomicilio && "hidden"}`}
                      >
                        <table className="w-full text-sm text-primary-100">
                          <thead>
                            <tr className="bg-btnPrimary-100 text-white px-2">
                              <th className="text-left px-5">Domiclio</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*gerente.cteDomicilios.map((domicilio) => {
                              return (
                                <tr key={domicilio.DomCteId}>
                                  <td className="px-2 w-[35px] text-right">{domicilio.DomId}</td>
                                  <td>{domicilio.DomCalle}</td>
                                </tr>
                              );
                            })*/}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modalgerente mddom="" mdstdom=""/>
    </div>
  );
}

export default Gerentes;

/*
Autor: JUAN CARLOS ARREOLA LOPEZ
Fecha: 01/04/2024
Proyecto: Cobranza 
*/
import * as React from "react";
import { HashRouter , BrowserRouter, Route, Routes } from "react-router-dom";
import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";

// Layouts
import LayoutAdm from "./paginas/layout/LayoutAdm";
import LayoutAdmin from "./paginas/layout/LayoutAdmin";
import LayoutAutenticacion from "./paginas/layout/LayoutAutenticacion";

//Paginas
import Login from "./paginas/auth/Login";
import Recupera from "./paginas/auth/Recupera";

// Errores
import Error404 from "./paginas/Error404";

// Paginas Admin
import Home from "./paginas/admin/Home";

// Catalogos
import Asesores from "./paginas/admin/Catalogos/Asesores";
import Clientes from "./paginas/admin/Catalogos/Clientes";
import Gerentes from "./paginas/admin/Catalogos/Gerentes";
import Supervisores from "./paginas/admin/Catalogos/Supervisores";
import Ejecutivos from "./paginas/admin/Catalogos/Ejecutivos";

// Cobranza
import CapPagos from "./paginas/admin/Cobranza/CapPagos";
import ApPagos from "./paginas/admin/Cobranza/ApPagos";

//Configuracion
import Roles from "./paginas/admin/Configuracion/Roles";
import Permisos from "./paginas/admin/Configuracion/Permisos";
import CamPassword from "./paginas/admin/Configuracion/CamPassword";
import Usuarios from "./paginas/admin/Configuracion/Usuarios";
import Grupos from "./paginas/admin/Catalogos/Grupos";

//Reportes
function App() {
  const [usuario, setUsuario] = React.useState("test");
  const [authState, setAuthState] = React.useState("Dashboard");

  React.useEffect(() => {
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          setUsuario(authenticatedUser.email); // verifica data de autenticate
          setAuthState("Dashboard");
        } else {
          setUsuario(null);
          setAuthState("login");
        }
      }
    );
    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-20">Cargando...</div>
    );
  return (
    <div>
      <HashRouter >
        <Routes> 
          <Route path="/auth" element={<LayoutAutenticacion />}>
            <Route index element={<Login />} />
            <Route path="recuperar" element={<Recupera />} />
          </Route>    
          <Route path="/" element={<LayoutAdm />}>
            <Route index element={<Home />} />    
            <Route path="cat/clientes" element= {<Clientes/>} />
            <Route path="cat/grupos" element= {<Grupos/>} />
          </Route>    
          <Route path="/adm" element={<LayoutAdmin />}>
            <Route index element={<Home />} />                
            {/* Catalogos */}
            <Route path="cat/grupos" element= {<Grupos/>} />
            <Route path="cat/asesores" element= {<Asesores/>} />
            <Route path="cat/clientes" element= {<Clientes/>} />
            <Route path="cat/ejecutivos" element= {<Ejecutivos/>} />
            <Route path="cat/gerentes" element= {<Gerentes/>} />
            <Route path="cat/supervisores" element= {<Supervisores/>} />
           
           {/* Cobranza */}
           <Route path="cob/cappagos" element= {<CapPagos/>} />
            <Route path="cob/appagos" element= {<ApPagos/>} />
            <Route path="cob/despagos" element= {<ApPagos/>} />
            
            {/* Configuracion */}
            <Route path="cnf/roles" element= {<Roles/>} />
            <Route path="cnf/permisos" element= {<Permisos/>} />
            <Route path="cnf/campassword" element= {<CamPassword/>} />
            <Route path="cnf/usuarios" element= {<Usuarios/>} />            
          </Route>
          {/* Errores */}
          <Route path="*" element={<Error404 />} />
        </Routes>
      </HashRouter >
    </div>
  );
}
export default App;

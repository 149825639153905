import React, { useTransition } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormCliente } from "../hooks/useFormCliente";
import { Alertform } from "./Alertform";
import {
  RiCloseLine,
  RiEraserLine,
  RiHome7Fill,
  RiPencilFill,
} from "react-icons/ri";
import axios from "axios";

const initialForm = {
  id: "0",
  nombre: "",
  fecharegistro:"",
  telefono: "",
  correo: "",
  estatus: "ACTIVO",
  tienedomicilio: false,
};

const validationsForm = (form) => {
  let errores = {};
  /* */
  if (!form.nombre.trim()) {
    errores.nombre = "El campo nombre de cliente esta vacio.";
  }
  if (!form.correo.trim()) {
    errores.email = "El campo correo electronico esta vacia.";
  }
  if (!form.telefono.trim()) {
    errores.telefono = "El campo Telefono esta vacio.";
  } else {
  }

  /* */
  return errores;
};

const Modalgerente = ({ nombre, mdstatus }) => {
  const [modcteid, setModcteid] = useState("1");
  const {
    form,
    errores,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCancelar,
    handleClose,
    handleCanelar,
  } = useFormCliente(initialForm, validationsForm);
  const [toogle, setToggle] = useState(1);
  function selectToogle(id) {
    setToggle(id);
  }

  const cambiarEstado = () => {};

  /* */
  const clicClose = () => {
    mdstatus(false);
  };

  return (
    <>
      <div className="fixed inset-0 bg-primary z-40 bg-opacity-40 bacground-blur-sm flex justify-center items-center">
        <div className=" dark:bg-gray-700 p-2 rounded flex flex-col justify-between items-center gap-2 w-[95%] h-[90%]">
          <div className="flex flex-row w-full justify-between">
            <h1 className="px-1 text-2xl text-bold">
              {form.id === "0" ? "Agregar " : "Modificar "}Gerente
            </h1>
            <button
              onClick={handleClose}
              className="bg-primary w-[25px] h-[25px] rounded text-white p-1"
            >
              <RiCloseLine />
            </button>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-[90%] absolute top-[15vh] z-30 gap-5 grid grid-col-1 lg:grid-cols-6 px-5"
          >
            {/* nombre del cliente */}
            <div className="col-span-3">
              <label className="flex w-full relative">
                <input
                  className="bg-white ring-2 text-primary uppercase ring-gray-400 w-full h-8 rounded peer px-3 transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                  type="text"
                  name="nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={form.nombre}
                  required
                />
                <span className="absolute top-1/2 -translate-y-1/2 left-3 peer-focus:top-0 peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2 cursor-text peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold text-gray-500 flex items-center gap-2">
                  Nombre de gerente <span className="text-red-500">*</span>
                </span>
              </label>
            </div>
            {/* fecha registro */}
            <div className="col-span-1">
              <label className="flex w-full relative">
                <input
                  type="text"
                  name="fecharegistro"
                  value={form.fecharegistro}
                  readonly
                  className="bg-white ring-2 text-center text-primary uppercase ring-gray-400 w-full h-8 rounded peer px-3 transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                />
                <span className="absolute top-1/2 -translate-y-1/2 left-3 peer-focus:top-0 peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2 cursor-text peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold text-gray-500 flex items-center gap-2">
                  Fecha de registro
                </span>
              </label>
            </div>
            {/* fecha registro */}
            <div className="col-span-1">
              <label className="flex w-full relative">
                <input
                  type="text"
                  name="fecharegistro"
                  value={form.estatus}
                  readonly
                  className="bg-white ring-2 text-primary uppercase ring-gray-400 w-full h-8 rounded peer px-3 transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                />
                <span className="absolute text-center top-1/2 -translate-y-1/2 left-3 peer-focus:top-0 peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2 cursor-text peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold text-gray-500 flex items-center gap-2">
                  Estatus
                </span>
              </label>
            </div>

            <div className="flex flex-col col-span-1 row-span-4 justify-center items-center gap-5">
              <button className="bg-primary w-[200px] h-[35px] rounded text-white p-1 ">
                Guardar
              </button>
              <button
                className="bg-orange-200 w-[200px] h-[35px] rounded text-primary p-1 "
                onClick={handleCancelar}
              >
                Limpiar
              </button>
              <div className="flex flex-row items-center justify-center text-violet-900 inset-0 text-2xl z-50  w-[2000px] h-[100px]">
                {loading}
              </div>
            </div>
            {/* Telefono */}
            <div className="col-span-1">
              <label className="flex w-full relative">
                <input
                  name="telefono"
                  type="telefono"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={form.telefono}
                  className="bg-white ring-2 text-left lowercase text-primary ring-gray-400 w-full h-8 rounded peer px-3 transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                  required
                />
                <span className="absolute top-1/2 -translate-y-1/2 left-3 peer-focus:top-0 peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2 cursor-text peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold text-gray-500 flex items-center gap-2">
                  Telefono
                </span>
              </label>
            </div>
            {/* Correo electronico */}
            <div className="col-span-2">
              <label className="flex w-full relative">
                <input
                  name="correo"
                  type="correo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={form.correo}
                  className="bg-white ring-2 text-left lowercase text-primary ring-gray-400 w-full h-8 rounded peer px-3 transition-all outline-none focus:ring-gray-400 valid:ring-gray-400"
                  required
                />
                <span className="absolute top-1/2 -translate-y-1/2 left-3 peer-focus:top-0 peer-focus:text-xs peer-focus:font-semibold transition-all bg-white px-2 cursor-text peer-valid:top-0 peer-valid:text-xs peer-valid:font-semibold text-gray-500 flex items-center gap-2">
                  Correo electronico
                </span>
              </label>
            </div>
            <div className="w-full "></div>
          </form>
          <div className="hidden absolute flex flex-col items-center top-[30vh] z-40 mx-10 px-5">
            <div class="border-b border-gray-200 dark:border-gray-700">
              <ul class="flex flex-wrap -mb-px text-sm font-medium text-left text-gray-500 dark:text-gray-400">
                <li className="me-2">
                  <Link
                    onClick={() => selectToogle(1)}
                    className="inline-flex items-center justify-center p-4 text-primary border-b-2 border-orange-200 rounded-t-lg active dark:text-orange-200 dark:border-orange-200 group"
                    aria-current="page"
                  >
                    <svg
                      class="w-4 h-4 me-2 text-orange-200 dark:text-orange-200"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 18"
                    >
                      <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                    </svg>
                    Domicilios
                  </Link>
                </li>

                <li class="me-2">
                  <Link
                    onClick={() => selectToogle(2)}
                    class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                    aria-current="page"
                  >
                    <svg
                      class="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5 11.424V1a1 1 0 1 0-2 0v10.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.228 3.228 0 0 0 0-6.152ZM19.25 14.5A3.243 3.243 0 0 0 17 11.424V1a1 1 0 0 0-2 0v10.424a3.227 3.227 0 0 0 0 6.152V19a1 1 0 1 0 2 0v-1.424a3.243 3.243 0 0 0 2.25-3.076Zm-6-9A3.243 3.243 0 0 0 11 2.424V1a1 1 0 0 0-2 0v1.424a3.228 3.228 0 0 0 0 6.152V19a1 1 0 1 0 2 0V8.576A3.243 3.243 0 0 0 13.25 5.5Z" />
                    </svg>
                    Prestamos
                  </Link>
                </li>
                <li class="me-2">
                  <a
                    onClick={() => selectToogle(3)}
                    href="#"
                    class="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group"
                  >
                    <svg
                      class="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 20"
                    >
                      <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
                    </svg>
                    Pagos
                  </a>
                </li>
              </ul>
            </div>
            <div className={toogle === 1 ? "block" : "hidden"}>
              <div className="flex py-5">
                <button className=" flex items-center gap-2 h-[35px] bg-primary text-base text-white p-2 rounded-[5px]">
                  <RiHome7Fill />
                  Agregar domicilio
                </button>
              </div>
              <div className="w-[90vw] flex justify-center ">
                <table className="w-full">
                  <thead>
                    <tr className="bg-primary ">
                      <th>Calle</th>
                      <th>Colonia</th>
                      <th>Ciudad</th>
                      <th>Estado</th>
                      <th>Codigo postal</th>
                      <th>Estatus</th>
                      <th>accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left font-[8px]">
                        PASEO DE LOS ABETOS PONIENTE
                      </td>
                      <td>VALLE DE SAN ISIDRO</td>
                      <td>ZAPOPAN</td>
                      <td>JALISCO</td>
                      <td>44530</td>
                      <td>ACTUAL</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-white" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-white" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-[8px]">
                        PASEO DE LOS ABETOS PONIENTE
                      </td>
                      <td>VALLE DE SAN ISIDRO</td>
                      <td>ZAPOPAN</td>
                      <td>JALISCO</td>
                      <td>44530</td>
                      <td>ACTUAL</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center bg-white text-primary rounded-full">
                            1
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-green-900 rounded-full">
                            2
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-blue-900 rounded-full">
                            3
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-[8px]">
                        PASEO DE LOS ABETOS PONIENTE
                      </td>
                      <td>VALLE DE SAN ISIDRO</td>
                      <td>ZAPOPAN</td>
                      <td>JALISCO</td>
                      <td>44530</td>
                      <td>ACTUAL</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center bg-white text-primary rounded-full">
                            1
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-green-900 rounded-full">
                            2
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-blue-900 rounded-full">
                            3
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-[8px]">
                        PASEO DE LOS ABETOS PONIENTE
                      </td>
                      <td>VALLE DE SAN ISIDRO</td>
                      <td>ZAPOPAN</td>
                      <td>JALISCO</td>
                      <td>44530</td>
                      <td>ACTUAL</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center bg-white text-primary rounded-full">
                            1
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-green-900 rounded-full">
                            2
                          </div>
                          <div className="w-[24px] h-[24px] text-center bg-blue-900 rounded-full">
                            3
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={toogle === 2 ? "block" : "hidden"}>
              <div className="flex py-5">
                <button className=" flex items-center gap-2 h-[35px] bg-primary text-base text-white p-2 rounded-[5px]">
                  <RiHome7Fill />
                  Nuevo prestamo
                </button>
              </div>
              <div className="w-[90vw] flex justify-center ">
                <table className="w-full">
                  <thead>
                    <tr className="bg-primary ">
                      <th>Ejercicio</th>
                      <th>Fecha Inicio</th>
                      <th>Fecha final</th>
                      <th>Prestado</th>
                      <th>Pagos</th>
                      <th>Estatus</th>
                      <th>accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-left font-[8px]">00001</td>
                      <td> 2024-01-01</td>
                      <td> 2024-01-01</td>
                      <td>$ 1,200.00</td>
                      <td>12/12</td>
                      <td>PAGADO</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-[8px]">00001</td>
                      <td> 2024-01-01</td>
                      <td> 2024-01-01</td>
                      <td>$ 1,200.00</td>
                      <td>
                        <span className="text-primary">12/13</span>
                      </td>
                      <td>PENDIENTE</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left font-[8px]">00001</td>
                      <td> 2024-01-01</td>
                      <td> 2024-01-01</td>
                      <td>$ 1,200.00</td>
                      <td>8/12</td>
                      <td>PENDIENTE</td>
                      <td>
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className={toogle === 3 ? "block" : "hidden"}>
              <div className="flex py-5">
                <button className=" flex items-center gap-2 h-[35px] bg-primary text-base text-white p-2 rounded-[5px]">
                  <RiHome7Fill />
                  Pagar recibo
                </button>
              </div>
              <div className="w-[90vw] flex justify-center ">
                <table className="w-full">
                  <thead>
                    <tr className="bg-primary ">
                      <th>Pago</th>
                      <th>Fecha vencimiento</th>
                      <th>Importe</th>
                      <th>Fecha pago</th>
                      <th>Estatus</th>
                      <th>accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="font-[8px]">
                      <td className="text-center">10/13</td>
                      <td className="text-center"> 2024-01-01</td>
                      <td className="text-right"> $ 120.00</td>
                      <td></td>
                      <td className="text-center">VENCIDO</td>
                      <td className="text-center">
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="font-[8px]">
                      <td className="text-center">11/13</td>
                      <td className="text-center"> 2024-01-01</td>
                      <td className="text-right"> $ 120.00</td>
                      <td></td>
                      <td className="text-center">PENDIENTE</td>
                      <td className="text-center">
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="font-[8px]">
                      <td className="text-center">12/13</td>
                      <td className="text-center"> 2024-01-01</td>
                      <td className="text-right"> $ 120.00</td>
                      <td></td>
                      <td className="text-center">PENDIENTE</td>
                      <td className="text-center">
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="font-[8px]">
                      <td className="text-center">13/13</td>
                      <td className="text-center"> 2024-01-01</td>
                      <td className="text-right"> $ 120.00</td>
                      <td></td>
                      <td className="text-center">PENDIENTE</td>
                      <td className="text-center">
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="font-[8px]">
                      <td className="text-center">1/13</td>
                      <td className="text-center"> 2024-01-01</td>
                      <td className="text-right"> $ 120.00</td>
                      <td></td>
                      <td className="text-center">PAGADO</td>
                      <td className="text-center">
                        <div className="flex justify-center gap-2">
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiPencilFill className="text-gray-500" />
                          </div>
                          <div className="w-[24px] h-[24px] text-center text-white rounded-full">
                            <RiEraserLine className="text-gray-500" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="flex flex-row w-full h-full grid grid-col-1 lg:grid-cols-6 "></div>
          </div>
        </div>
        <div></div>

        {/*<Modaldomiciliocliente mdcteid={modcteid} mddom="" mdstdom=""/> */}
      </div>
    </>
  );
};
export default Modalgerente;

import { React, useState } from "react";
import { Link } from "react-router-dom";
import { 
  RiMailLine,
  RiUser3Line  } from "react-icons/ri";
  //


function Header() {
  const [username, setUsername] = useState("Usuario");
  const [notificacion, setNotificacion] = useState('');
  return (
    <div className="flex flex-row justify-end bg-secondary-200 h-[10vh] text-white">

      {/* notificacion */}
      <ul className="flex items-center gap-5 py-10 pr-10">
        <li>
     
          <Link to="/messages" className={`flex gap-1 text-[14px] ${notificacion?"text-invprimary":"text-gray-500"}`}>Notificacion <RiMailLine className="mt-1 text-1xl" />
           {
           notificacion?(<span className="relative -top-1 -left-2 text-white text-[9px] bg-deeppink h-[13px] w-[20px] py-.5 rounded-sm	 text-center ">{notificacion}</span>):(<></>)
           }
          
         </Link>
        </li>
        <li>
          <Link to="/perfil" className="flex gap-1 text-[14px]">{username} <RiUser3Line  className="text-[16px] pt-1" /></Link>
        
        </li>
      </ul>
    </div>
  );
}

export default Header;


import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage ,ref,uploadBytes} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCS_6bV5lu_TNfXFRddMmXaF4r4A2-_CpU",
  authDomain: "cobranza-371d0.firebaseapp.com",
  projectId: "cobranza-371d0",
  storageBucket: "cobranza-371d0.appspot.com",
  messagingSenderId: "212562650551",
  appId: "1:212562650551:web:7c9089dce8c95a997b3432",
  measurementId: "G-BFLZS2C8TZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // auth 


export const storage = getStorage(app);// storage 

export function upfileFirebase(file) {
  console.log(file)
 const strageRef = ref(storage, file.name);
  uploadBytes(strageRef, file).then((snapshot) => {
    console.log(snapshot);
  })
}
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // localstorage el login data
    })
    .catch((err) => {});
};

import Tablagrupos from "../../../components/Catalogos/Tablagrupos"
import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  RiTextDirectionR,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
  RiHome2Fill,
  RiBillLine,
  RiUser2Fill,
  RiPrinterCloudFill,
  RiFileExcel2Fill, 
  RiEyeCloseLine,
  RiEyeLine
} from "react-icons/ri";
import { SiOpenstreetmap } from "react-icons/si";
import Modalcliente from "../../../components/Modalcliente";
import { dataClientes } from '../../../auth/data';

const  Grupos=() =>{   
    const [grupos, setGrupos] = useState([]);
    useEffect(() => {
        fetch("https://cobapi.jkarreola.com.mx/grupos")
          .then((result) => result.json())
          .then((data) => {
            setGrupos(data[0]);           
          }, []);
      }, []);
    return (
        <>
            <div className="w-full flex flex-col justify-between bg-white">
                <div className="w-full bg-primary-100 h-[5vh] text-black">
                </div>
                <div className="flex- flex-col justify-between 
                relative overflow-x-auto shadow-md  w-full h-[95vh] bg-white">
                    <div>
                        <span className="text-2xl text-gray-600 pl-5">Grupos</span>
                    </div>
                    <div className="w-full flex flex-cols gap-3 justify-end flex py-2 pr-2">
                        <button className="flex items-center gap-2 h-[35px] bg-primary-100 text-base text-white p-2 rounded-[5px]">
                            <RiUser2Fill />
                        </button>
                        <button className=" flex items-center gap-2 h-[35px] bg-green-800 text-base text-white p-2 rounded-[5px]">
                            <RiFileExcel2Fill />
                        </button>
                        <button className=" flex items-center gap-2 h-[35px] bg-blue-800 text-base text-white p-2 rounded-[5px]">
                            <RiPrinterCloudFill />
                        </button>
                    </div> 
                   <Tablagrupos datagrupos={grupos} />
                </div>               
            </div>
        </>
    )
}
export default Grupos
import React from 'react'

function CapPagos() {
  return (
    <div>
      Captura Pagos
    </div>
  )
}

export default CapPagos

import { useState } from "react";
export const useFormCliente = (initialForm,validateForm) => {
  /*  Variables de estado */
  const [form, setForm] = useState(initialForm);
  const [errores, setErrores] = useState({});
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  /*                Metodos              */
  /*  Al cambiar                  */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  /*  Al perder el foco          */
  const handleBlur = (e) => {
    handleChange(e);
    setErrores(validateForm(form));
  };
  /*  Al perder hacer submit  */
  const handleSubmit = (e) => {
//  

  };
  /*  Al perder hacer submit  */
  const handleCancelar = (e) => {  setForm(initialForm);};

  return {
    form,
    errores,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
    handleCancelar,
  };
};

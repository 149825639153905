import { useState, useEffect } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
    RiEraserFill,
  RiPencilFill
  } from "react-icons/ri";
const Tablagrupos=({datagrupos})=>{     
    return (
        <div className="w-full">
            <table className="w-full">
                <thead>
                    <tr className="bg-tbprimary-100">
                        <th></th>
                        <th>Descripcion</th>
                        <th>Fecha registro</th>
                        <th>Estatus</th></tr>
                </thead>
                <tbody> 
                {
                datagrupos.map((grupo) => {
                    return(<tr className="text-primary-100" key={grupo.gpoId}>                       
                        <td className="flex gap-2 px-3"><RiPencilFill /><RiEraserFill/></td>
                        <td className="px-3">{grupo.gpoNombre}</td>
                        <td>{format(grupo.gpoFecharegistro, "yyyy/MM/dd", { locale: es })}</td>
                        <td>{grupo.gpoNombre?'ACTIVO':'INACTIVO'}</td>
                    </tr>)
                })} 
                </tbody>
            </table>
        </div>
    )
}
export default Tablagrupos
import {React,useState} from "react";
import { Link } from "react-router-dom";
import {
  RiHome4Line,
  RiNewspaperLine,
  RiSettingsLine,
  RiSlackLine,
  RiLogoutBoxLine,
  RiArrowDropRightLine,
  RiArrowDropUpLine,
  RiArrowDropRightFill,
  RiMoneyDollarBoxLine,
} from "react-icons/ri";
const Sidebar = () => {
  const [submenucat,setSubmenucat]=useState(false);
  const [submenucob,setSubmenucob]=useState(false);
  const [submenuset,setSubmenuset]=useState(false);
  const [submenurep,setSubmenurep]=useState(false);
  return (
    <div className="flex flex-col justify-between xl:h-[100vh] overflow-y-scroll fixed xl:static w-full h-full -left-full top-0 bg-secondary-100 ">
      <div>
        <h1 className="text-center text-2xl font-bold text-white mb-10">
          Cobranza<span className="text-primary text4xl">.</span>
        </h1>
        <ul>
          <li>
            <Link
              to="/"
              className="w-full hover:bg-secondary-900 transition-colors flex items-center text-[1rem] font-bold gap-4 py-1 px-2 mb-1 rounded-md"
            >
              <RiHome4Line className="text-primary text-4xl" />
              <span className="w-full text-left">Inicio</span> 
            </Link>
          </li>
          <li>
            <button
              onClick={()=>setSubmenucat(!submenucat)}
              to="/"
              className="w-full flex justify-between hover:bg-secondary-900 transition-colors flex items-center text-[1rem] font-bold gap-4 py-1 px-2 mb-1 rounded-md"
            >
              <RiSlackLine className="text-primary text-4xl " />
              <span className="w-full text-left">Catalogos</span> 
              <RiArrowDropRightLine className={`font-bold text-4xl text-primary hover:text-white ${submenucat && "rotate-90"} transition-all`} />
            </button>
            <ul className={`py-4 ${!submenucat && "hidden"}`}>
            <li>
                <Link
                  to="cat/gerentes"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                   hover:tex-white transition-colors"
                >
                  Gerentes
                </Link>
              </li>
              <li>
              <Link
                  to="cat/ejecutivos"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Ejecutivos
                </Link>
              </li>
              <li>
              <Link
                  to="/cat/supervisores"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Supervisores
                </Link>
              </li>
              <li>
              <Link
                  to="cat/asesores"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Asesores
                </Link>
              </li>
              <li>
              <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Ejercicios
                </Link>
              </li>
              <li>
              <Link
                  to="/cat/clientes"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Clientes
                </Link>
              </li>
            </ul>
          </li>
          <li>
          <button
           onClick={()=>setSubmenucob(!submenucob)}
              to="cat/clientes"
              className="w-full flex items-center justify-between hover:bg-secondary-900 transition-colors  text-[1rem] font-bold gap-4 py-1 px-2 mb-1 rounded-md"
            >
              <RiMoneyDollarBoxLine className="text-primary text-4xl" />
             <span className="w-full text-left">Cobranza</span> 
             <RiArrowDropRightLine className={`font-bold text-4xl text-primary hover:text-white ${submenucob && "rotate-90"} transition-all`} />
            </button>
             
            <ul className={`py-4 ${!submenucob && "hidden"}`}>
            <li>
            <Link
                  to="cob/cappagos"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Captura pagos
                </Link>
              </li> 
              <li>
              <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Aplicacion de pagos
                </Link>
              </li>
              <li>
                <Link
                  to="cob/appagos"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                 
                  Deshacer pagos
                </Link>
              </li>
            </ul>
          </li>
          <li>
          <button
          onClick={()=>setSubmenurep(!submenurep)}
              to="cob/despagos"
              className="w-full flex  justify-between hover:bg-secondary-900 transition-colors flex items-center text-[1rem] font-bold gap-4 py-1 px-2 mb-1 rounded-md"
            >
              <RiNewspaperLine className="text-primary text-4xl" />
              <span className="w-full text-left">Reportes</span> 
              <RiArrowDropRightLine className={`font-bold text-4xl text-primary hover:text-white ${submenurep && "rotate-90"} transition-all`} />
            </button>
            <ul className={`py-4 ${!submenurep && "hidden"}`}>
            <li>
            <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Reporte 1
                </Link>
              </li> 
              <li>
              <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Reporte 2
                </Link>
              </li>
            </ul>
          </li>
          <li>
          <button
          onClick={()=>setSubmenuset(!submenuset)}
              to="/"
              className="w-full flex items-center justify-between hover:bg-secondary-900 transition-colors flex items-center text-[1rem] font-bold gap-4 py-1 px-2 mb-1 rounded-md"
            >
              <RiSettingsLine className="text-primary text-4xl" />
              <span className="w-full text-left">Configuracion</span> 
              <RiArrowDropRightLine className={`font-bold text-4xl text-primary hover:text-white ${submenuset && "rotate-90"} transition-all`} />
            </button>
            <ul className={`py-4 ${!submenuset && "hidden"}`}>
            <li>
            <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Perfil
                </Link>
              </li> 
            <li>
            <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Usuarios
                </Link>
              </li> 
              <li>
              <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Cambio contraseña
                </Link>
              </li>
              <li>
              <Link
                  to="/"
                  className="border-l border-secondary py-1 px-8 
                  text-[.8rem] border-lborder-red-300 ml-4
                  block relative before:w-3 before:h-3 before:absolute
                  before:bg-gray-600 before:rounded-full before:border-2
                   before:border-secondary-100 before:-left-[7px] 
                   before:top-1/2 before:-translate-y-1/2
                  "
                >
                  Roles
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>{" "}
      <Link
        to="/"
        className="hover:bg-secondary-900 transition-colors  flex items-center font-bold gap-4 py-1 px-2 mb-1 rounded-md"
      >
        <RiLogoutBoxLine className="text-primary text-4xl" />
        <span className="w-full text-left">Salir</span> 
      </Link>
    </div>
  );
};
export default Sidebar;

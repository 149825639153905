import { RiHome4Line, RiPulseLine } from "react-icons/ri";
import Boxinfo from "../../../components/Boxinfo";
const Home = () => {
  return (
    <div className="w-full px-3 py-3 grid grid-cols-6">
   
    </div>
  );
};
export default Home;

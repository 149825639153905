import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import {
  RiTextDirectionR,
  RiArrowDropRightLine,
  RiArrowDropLeftLine,
  RiHome2Fill,
  RiBillLine,
  RiUser2Fill,
  RiPrinterCloudFill,
  RiFileExcel2Fill, 
  RiEyeCloseLine,
  RiEyeLine
} from "react-icons/ri";
import { SiOpenstreetmap } from "react-icons/si";
import Modalcliente from "../../../components/Modalcliente";
import { dataClientes } from '../../../auth/data';

function Clientes() {
  const [verdetalles, setVerdetalles] = useState(false);
  const [contactos, setContactos] = useState(false);
  const [prestamos, setPrestamos] = useState(true);
  const [domicilios, setDomicilios] = useState(false);
  const [clientes, setClientes] = useState([]);


  useEffect(() => {
    fetch("https://cobapi.jkarreola.com.mx/clientes")
      .then((result) => result.json())
      .then((data) => {
        setClientes(data);
        console.log(clientes)
      }, []);
  }, []);
  return (
    <>
      <div className="w-full
                      flex flex-col justify-between bg-white">
        <div className="w-full bg-primary-100 h-[5vh] text-black">
        </div>
        <div className="flex- flex-col justify-between relative overflow-x-auto shadow-md
        w-full h-[95vh] bg-white">
          <div>
            <span className="text-2xl text-gray-600 pl-5">Clientes</span>
          </div>
          <div className="w-full flex flex-cols gap-3 justify-end flex py-2 pr-2">
            <button className="flex items-center gap-2 h-[35px] bg-primary-100 text-base text-white p-2 rounded-[5px]">
              <RiUser2Fill />
            </button>
            <button className=" flex items-center gap-2 h-[35px] bg-green-800 text-base text-white p-2 rounded-[5px]">
              <RiFileExcel2Fill />
            </button>
            <button className=" flex items-center gap-2 h-[35px] bg-blue-800 text-base text-white p-2 rounded-[5px]">
              <RiPrinterCloudFill />
            </button>

          </div>
          <div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-red-800 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="w-full px-5">Nombre</th>
                </tr>
              </thead>
              <tbody>
                {clientes.map((cliente) => {
                  return (
                    <tr                    
                      key={cliente.cteID}>
                      <td >
                        <div className="w-full flex justify-between  pl-4 font-bold text-red-900 text-[1rem]">
                         
                          <span  onClick={() => setVerdetalles(cliente.cteID) } className="w-full flex items-left"> <RiArrowDropRightLine className="text-[1.5rem]" />{cliente.cteID}-{cliente.ctenombre} ({cliente.grupo[0].gponombre})</span><Link onClick={() => setVerdetalles(null) } className="pr-5">X</Link></div>

                        <div className={`ml-10  px-5 ${verdetalles == cliente.cteID || "hidden"}`}>
                          <p>
                            <span className="font-bold">Calle: </span>{cliente.domicilios[0].domcalle}<br />
                            <span className="font-bold">Colonia: </span>{cliente.domicilios[0].domcolonia} <span className="font-bold">CPostal: </span>{cliente.domicilios[0].domcpostal}<br />
                            <span className="font-bold">Ciudad: </span>{cliente.domicilios[0].domciudad}<br />
                            <span className="font-bold">Correo electronico: </span>{cliente.ctecorreo}<br />
                            <span className="font-bold">Telefono: </span>{cliente.ctetelefono}</p>
                            <span className="text-[1rem] flex font-bold text-red-800 gap-2"
                             onClick={() => setContactos(!contactos)}><span className={`${!contactos && "hidden"} pt-1 `}><RiEyeLine /></span><span className={`${!!contactos && "hidden"}  pt-1`}><RiEyeCloseLine /></span> Contactos</span><br />
                          <div className={`w-full   grid grid-cols-3 gap-4 ml-10 px-5 ${!contactos && "hidden"}`} > 
                              <div className="bg-gray-200 shadow-sm  rounded-lg p-1">
                              <p><span className="font-bold">Datos de ejecutivo</span>
                                <br />{cliente.ejecutivo[0].ejenombre}
                                <br />{cliente.ejecutivo[0].ejecorreo}
                                <br />{cliente.ejecutivo[0].ejetelefono}</p>
                          </div>
                          <div className="bg-gray-200 shadow-sm rounded-lg p-1">
                            <p>
                              <span className="font-bold">Datos de gerente</span>
                              <br />{cliente.gerente[0].gernombre}
                              <br />{cliente.gerente[0].gercorreo}
                              <br />{cliente.gerente[0].gertelefono}
                            </p>
                            </div>
                            <div className="bg-gray-200 shadow-sm rounded-lg p-1">
                            <p>
                              <span className="font-bold">Datos de Asociado</span>
                              <br />{cliente.gerente[0].asonombre}
                              <br />{cliente.gerente[0].gercorreo}
                              <br />{cliente.gerente[0].gertelefono}
                            </p>
                            </div>
                            <div className="bg-gray-200 shadow-sm rounded-lg p-1">
                            <p>
                              <span className="font-bold">Datos de Suprvisor</span>
                              <br />{cliente.gerente[0].asonombre}
                              <br />{cliente.gerente[0].gercorreo}
                              <br />{cliente.gerente[0].gertelefono}
                            </p>
                            </div>
                            <div className="bg-gray-200 shadow-sm rounded-lg p-1">
                            <p>
                              <span className="font-bold">Datos de Asesor</span>
                              <br />{cliente.gerente[0].asonombre}
                              <br />{cliente.gerente[0].gercorreo}
                              <br />{cliente.gerente[0].gertelefono}
                            </p>
                            </div>
                           
                            </div>
                            <span className="text-[1rem] flex font-bold text-red-800 gap-2"
                             onClick={() => setPrestamos(!prestamos)}><span className={`${!prestamos && "hidden"} pt-1 `}><RiEyeLine /></span><span className={`${!!prestamos && "hidden"}  pt-1`}><RiEyeCloseLine /></span> Prestamos</span><br />
                          <table className={`w-full  ${!prestamos && "hidden"}`}>
                            <thead>
                            <tr className="w-full bg-gray-600 text-white">
                              <th>Prestamo</th>
                              <th>Monto</th>
                              <th>Fechapago </th>
                              <th>Fecha inicio</th>
                              <th>Fecha final</th>
                              </tr>
                            </thead>
                            <tbody>
                            {cliente.domicilios.map((dom) => {
                              return(  
                              <tr className="w-full" key={dom.domid}>
                              <td>{dom.domid}</td>
                              <td>$10,000.00</td>
                              <td>2024-01-01</td>
                              <td>2024-01-01</td>
                              <td>2024-01-01</td>
                              </tr>
                           )
                            })}
                          </tbody>
                          </table>
                          <span className="text-[1rem] flex font-bold text-red-800 gap-2"
                             onClick={() => setDomicilios(!domicilios)}><span className={`${!domicilios && "hidden"} pt-1 `}><RiEyeLine /></span><span className={`${!!domicilios && "hidden"}  pt-1`}><RiEyeCloseLine /></span> Domicilios</span><br />
                           <div className="w-full flex flex-cols gap-3 justify-start flex py-2 pr-2">
            <button onClick={()=>{alert("agergar domiclio")}}className={`flex items-center gap-2 h-[35px] bg-green-500 text-base text-white p-2 rounded-[5px] ${!domicilios && "hidden"}`}>
            <SiOpenstreetmap />
            </button>
           

          </div>
                          <table className={`w-full  ${!domicilios && "hidden"}`}>
                            <thead>
                            <tr className="w-full bg-gray-600 text-white">
                              <th>Calle</th>
                              <th>Colonia</th>
                              <th>CPostal</th>
                              <th>Ciudad</th></tr>
                            </thead>
                            <tbody>
                            { 
                            cliente.domicilios.map((dom) => {                                                       
                              return(  
                              <tr className="w-full" key={dom.domid}>
                              <td>{dom.domcalle}</td>
                              <td>{dom.domcolonia}</td>
                              <td>{dom.domcpostal}</td>
                              <td>{dom.domciudad}</td>
                              </tr>
                           );
                         
                            })}
                          </tbody>
                          </table>

                        </div>
                      </td>
                    </tr>)
                })}
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default Clientes;
import React from 'react'

function Usuarios() {
  return (
    <div>
      Usuarios
    </div>
  )
}

export default Usuarios

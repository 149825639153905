import React from 'react'

function ApPagos() {
  return (
    <div>
      Aplicaicion Pagos
    </div>
  )
}

export default ApPagos

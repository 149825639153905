import React from 'react'

function Permisos() {
  return (
    <div>
      Permisos
    </div>
  )
}

export default Permisos

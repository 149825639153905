import {React,useEffect,useState} from "react"
import { Outlet } from "react-router-dom";
import Sidebar2 from "../../components/Sidebar2"

const LayoutAdm=()=>{
    const perfil=[{usuario:"juank"}];
    return(
        <>
            <div className="flex flex-row">
             <Sidebar2 perfil={perfil}/>
             <Outlet />
            </div>        
        </>
    )
}
export default LayoutAdm